import { useState } from "react"
import { loadState, saveState } from "shared/localstorage_helpers"

export function useLocalStorage(stateId, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    const item = loadState(stateId)
    if (item === undefined) return initialValue

    return item
  })

  const setValue = value => {
    const valueToStore = value instanceof Function ? value(storedValue) : value
    setStoredValue(valueToStore)
    saveState(valueToStore, stateId)
  }

  return [storedValue, setValue]
}
