import { Component } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { patch } from "shared/lib/api"
import StoredFieldsCollection from "./stored_fields_collection"
import includes from "lodash/includes"

export default class AppletStoredFieldsForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fieldsReady: {},
      errors: {},
      loading: false,
      canAsyncRetry: false,
      name: props.applet.name,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onError = this.onError.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onInvalidResolution = this.onInvalidResolution.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)

    if (this.isFormEnabled(this.props, this.state)) {
      this.props.onFormEnabled()
    } else {
      this.props.onFormDisabled()
    }
  }

  componentDidMount() {
    //listener for browser back button
    window.addEventListener("popstate", this.handlePageChange)
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handlePageChange)
  }

  componentDidUpdate(prevProps, prevState) {
    const formWasEnabled = this.isFormEnabled(prevProps, prevState)
    const formIsEnabled = this.isFormEnabled(this.props, this.state)

    if (!formWasEnabled && formIsEnabled) {
      this.props.onFormEnabled()
    } else if (formWasEnabled && !formIsEnabled) {
      this.props.onFormDisabled()
    }
  }

  handlePageChange() {
    if (this.props.applet.status === "never_enabled_for_user") {
      window.showSurvey = true
    }
  }

  onStoredFieldReady = fieldOwner => {
    setTimeout(() => {
      let fieldsReady = Object.assign({}, this.state.fieldsReady)
      fieldsReady[fieldOwner] = true
      this.setState({ fieldsReady })
    }, 0)
  }

  onInvalidResolution(error, fieldName, field) {
    let errors = {}
    // TODO: update this once we get new error formats
    errors[`/fields/${field.name}`] = "Please reconnect your service"

    let asyncFieldTypes = [
      "collection_select",
      "double_collection_select",
      "checkbox_multi",
    ]
    this.setState({
      errors,
      canAsyncRetry: includes(asyncFieldTypes, field.field_subtype),
    })
    window.appendFlash("Error accessing the service", "danger")
  }

  onFieldChange(field, value) {
    let fields = this.state.fields || {}

    let owner = fields[field.owner] || {}
    owner[field.name] = value

    fields = Object.assign({}, fields, {
      [field.owner]: owner,
    })

    this.setState({ fields })
  }

  // NOTE: This can be called as a public method. See
  // `app/components/sdk/configure_page.js`.
  onSubmit(e, sdkSuccessCallback) {
    if (e) e.preventDefault()

    this.setState({ loading: true })

    return patch(this.props.submitUrl, {
      applet: {
        id: this.props.applet.id,
        name: this.state.name,
        stored_fields: this.state.fields,
      },
    })
      .then(response => this.onSuccess(response, sdkSuccessCallback))
      .catch(this.onError)
  }

  onSuccess(response, sdkSuccessCallback) {
    let message = "Applet successfully updated"

    if (this.props.applet.status === "never_enabled_for_user") {
      message = "Your Applet is now active"
    }

    // first case handles success of embedded sdk applets
    if (sdkSuccessCallback) {
      sdkSuccessCallback()
    } else {
      if (this.props.showSuccessMessage) {
        setTimeout(() => window.appendFlash(message), 1500)
      }
      window.location.assign(response.return_to)
    }
  }

  onError({ response: err }) {
    let message = `There was a problem saving this Applet`
    const details = get(err, "original.details", [])

    if (details.length > 0) {
      message = `${message} — see the errors below`
    }

    window.appendFlash(message, "danger")
    this.setState({ loading: false })

    if (err && err.error) {
      this.setState({ errors: err.error })
    }

    if (err && err.validation_errors) {
      this.setState({ validationErrors: err.validation_errors })
    }
  }

  isFormReady(props = this.props, state = this.state) {
    return Object.keys(state.fieldsReady).length === props.storedFields.length
  }

  isFormEnabled(props = this.props, state = this.state) {
    return this.isFormReady(props, state) && !state.loading
  }

  storedFields() {
    return (
      <StoredFieldsCollection
        applet={this.props.applet}
        permissions={this.props.applet.permissions}
        fields={this.props.storedFields}
        ingredientsMetadata={this.props.ingredientsMetadata}
        onInvalidResolution={this.onInvalidResolution}
        onFieldChange={this.onFieldChange}
        onStoredFieldReady={this.onStoredFieldReady}
        validationErrors={this.state.validationErrors}
        canAsyncRetry={this.state.canAsyncRetry}
        useInlineErrors={this.props.useInlineErrors}
        includeDescriptions={this.props.includeDescriptions}
        userAllowMultipleLiveChannels={false}
      />
    )
  }

  render() {
    const className = this.state.loading ? "loading" : ""

    return (
      <form onSubmit={this.onSubmit} className={className}>
        <section className="stored_fields">
          <div className="fieldset">{this.storedFields()}</div>
        </section>

        {this.props.includeFooter && (
          <section className="save">
            <button
              type="submit"
              className="button-on-color button-primary"
              disabled={!this.isFormEnabled()}
            >
              Save
            </button>
          </section>
        )}
      </form>
    )
  }
}

AppletStoredFieldsForm.propTypes = {
  storedFields: PropTypes.array,
  ingredientsMetadata: PropTypes.object,
  applet: PropTypes.object,
  submitUrl: PropTypes.string,
  includeDescriptions: PropTypes.bool,
  includeFooter: PropTypes.bool,
  useInlineErrors: PropTypes.bool,
  onFormEnabled: PropTypes.func,
  onFormDisabled: PropTypes.func,
  showSuccessMessage: PropTypes.bool,
}

AppletStoredFieldsForm.defaultProps = {
  includeDescriptions: true,
  includeFooter: true,
  useInlineErrors: false,
  onFormEnabled: () => {},
  onFormDisabled: () => {},
  showSuccessMessage: true,
}
