export const initialState = {
  code: null,
  email: null,
  id: null,
  login: null,
  signedIn: false,
  authRequestedAt: null,
  tier: null,
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        email: action.payload.email || null,
        id: action.payload.id || null,
        login: action.payload.login || null,
        signedIn: action.payload.signedIn,
        availableClients: action.payload.available_clients || null,
        code: action.payload.code,
        tier: action.payload.tier,
        eligibleForTrial: action.payload.eligible_for_trial,
        eligibleTrialPeriod: action.payload.eligible_trial_period,
        appletQuotaSlotsTotal: action.payload.applet_quota_slots_total,
        appletQuotaSlotsRemaining: action.payload.applet_quota_slots_remaining,
        isTrialing: action.payload._trialing,
        subscriptionPaymentType: action.payload.user_subscriptions
          ? action.payload.user_subscriptions[0]?.payment_type
          : null,
      }
    }

    case "USER_UPGRADE": {
      return {
        ...state,
        tier: action.payload.tier,
        eligibleForTrial: action.payload.eligible_for_trial,
        appletQuotaSlotsTotal: action.payload.applet_quota_slots_total,
        appletQuotaSlotsRemaining: action.payload.applet_quota_slots_remaining,
      }
    }

    case "USER_AUTH_REQUESTED_AT": {
      return {
        ...state,
        authRequestedAt: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
