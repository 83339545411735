import Header from "./header"

import "./upsell.scss"

export interface TrialConfigUrls {
  proSubscriptionsUrl: string
  proPlusSubscriptionsUrl: string
}

export interface TrialConfigAssets {
  proIllustration: string
  proStudentIllustration: string
  eo1Illustration: string
  smartHomeIllustration: string
}
interface Props {
  goal: string
  onSkip: () => void
  onClickBack: () => void
  onClickContinue: (evt: MouseEvent) => void
  skipHeader: boolean
  trialConfig: {
    assets: TrialConfigAssets
    urls: TrialConfigUrls
  }
  userIsStudent: boolean
}

const TITLES = {
  eo1: "Our best business tools",
  smart_home: "Top smart home tools",
  student: "Pro+ student discount",
  default: "Start your trial",
}

const BODIES = {
  eo1: "",
  smart_home: "Make the devices in your home work better together.",
  student:
    "Save 90% on your subscription and get full access to popular Applets and features. Make your favorite things work better together.",
  default:
    "Subscribe to get full access to popular Applets and features. Make your favorite things work better together.",
}

const BADGE_TIERS = {
  default: "pro",
  eo1: "pro",
  smart_home: "pro",
  student: "pro_plus",
}

const UPGRADE_URLS = {
  pro: "proSubscriptionsUrl",
  pro_plus: "proPlusSubscriptionsUrl",
}

const Upsell = ({
  goal,
  onSkip,
  trialConfig,
  onClickBack,
  onClickContinue,
  skipHeader,
  userIsStudent,
}: Props) => {
  let userType = goal
  if (userIsStudent) {
    userType = "student" // student discount has priority
  }

  const illustrations = {
    eo1: {
      url: trialConfig.assets.eo1Illustration,
      alt: "",
    },
    smart_home: {
      url: trialConfig.assets.smartHomeIllustration,
      alt: "",
    },
    student: {
      url: trialConfig.assets.proStudentIllustration,
      alt: "Subscribe to get full access",
    },
    default: {
      url: trialConfig.assets.proIllustration,
      alt: "Try Pro for Free",
    },
  }

  const title = TITLES[userType] || TITLES.default
  let body = BODIES[userType]
  if (body === undefined) {
    body = BODIES.default
  }
  const illustrationUrl = illustrations[userType]?.url || illustrations.default.url
  const illustrationAlt = illustrations[userType]?.alt || illustrations.default.alt
  const badgeTier = BADGE_TIERS[userType] || BADGE_TIERS.default
  const url = trialConfig.urls[UPGRADE_URLS[badgeTier]] || trialConfig.urls.proSubscriptionsUrl

  return (
    <>
      {!skipHeader && <Header badgeTier={badgeTier} title="" onClickBack={onClickBack} page="trial" />}
      <div styleName="container">
        <section styleName="welcome">
          <img src={illustrationUrl} alt={illustrationAlt} />
          <h1>{title}</h1>
          {!!body && <p styleName={body.length ? "with-spacer" : ""}>{body}</p>}
          <ul styleName="perks">
            <li>Use multiple actions.</li>
            <li>Access exclusive Pro services.</li>
            <li>Create up to 20 Applets.</li>
            <li>Your first 7 days are free!</li>
          </ul>
        </section>

        <div className="flexbox">
          <section styleName="action-buttons-container" className="action-buttons-container">
            <a
              href={url}
              onClick={onClickContinue}
              role="button"
              className="button-primary pay-later"
              data-dd-action-name="NUI Trial Continue"
            >
              {userIsStudent ? "Claim deal" : "Continue"}
            </a>

            <a onClick={onSkip} styleName="skip-pro-link" data-dd-action-name="NUI Trial Not now">
              Not now
            </a>
          </section>
        </div>
      </div>
    </>
  )
}

export default Upsell
