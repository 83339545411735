import { Component } from "react"
import PropTypes from "prop-types"
import { formatHexColorValue } from "shared/lib/alternate_brand_color"

import Header from "./header"
import Connect from "./connect"

import RubberBandPinchColor from "app/scripts/rubber_band_pinch_color"

import classnames from "classnames"

import debounce from "lodash/debounce"

import "./returning_user_flow.scss"

export default class TransitionAndRedirect extends Component {
  onAnimationEnd = () => {
    location.assign(this.props.activationPath)
  }

  fixViewportOnResize = () => {
    debounce(() => window.fixViewport())
  }

  componentDidMount() {
    window.addEventListener("resize", this.fixViewportOnResize)
    // make the rubberband area the same color as the header div
    RubberBandPinchColor()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.fixViewportOnResize)
  }

  render() {
    const {
      services,
      nextToConnect,
      user: { email, profile_image_url },
      createMagickAccount,
      beforeVerification,
      localization,
    } = this.props

    return (
      <div
        styleName={classnames("returning_user_flow", "after_verification")}
        className="connection-header-container"
        style={{
          backgroundColor: formatHexColorValue(nextToConnect.brand_color),
        }}
      >
        <Header
          services={services}
          idStrategy={email}
          avatar={profile_image_url}
          localization={localization.header}
        />
        <Connect
          nextToConnect={nextToConnect}
          email={email}
          onAnimationEnd={this.onAnimationEnd}
          createMagickAccount={createMagickAccount}
          beforeVerification={beforeVerification}
          localization={localization.connect}
          view="connecting_to"
        />
      </div>
    )
  }
}

TransitionAndRedirect.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      lrg_monochrome_image_url: PropTypes.string,
      lrg_variant_image_url: PropTypes.string,
    })
  ),
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    profile_image_url: PropTypes.string,
  }),
  onAnimationEnd: PropTypes.func,
  nextToConnect: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_name: PropTypes.string,
    brand_color: PropTypes.string.isRequired,
  }),
  activationPath: PropTypes.string.isRequired,
  createMagickAccount: PropTypes.bool,
  beforeVerification: PropTypes.bool,
  localization: PropTypes.shape({
    header: PropTypes.object.isRequired,
    connect: PropTypes.object.isRequired,
  }).isRequired,
}

TransitionAndRedirect.defaultProps = {
  services: [],
  createMagickAccount: false,
  beforeVerification: false,
}
