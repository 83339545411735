import { Component } from "react"
import PropTypes from "prop-types"

import ConnectButton from "./connect_button"

import ConnectedApplet from "./connected_applet"
import DisconnectedApplet from "./disconnected_applet"
import GrantAccess from "./grant_access"

import { reloadSensitiveDataAfterUpgrade } from "./api"

import UpgradePlanModal from "app/components/upgrade_plan_modal"
import { shouldUseAlternateBrandColor } from "shared/lib/alternate_brand_color"

import RubberBandPinchColor from "app/scripts/rubber_band_pinch_color"

import MobileAlert from "./mobile_alert"

import { connect } from "react-redux"
import { subscribe } from "redux-subscriber"

import { grantAccess, jumpToNextStep, requirementsMet } from "./reducers/connections"

import debounce from "lodash/debounce"

import { disableApplet, updateApplet } from "./reducers/applet"

import {
  updateNextToBeConnected,
  connectClicked,
  clearLocalStorage,
  updateAnimationCollection,
  appletCreated,
  appletDisabled,
  appletEnabled,
  updateAppletEnablementRules,
  setUser,
  upgradeUserSubscription,
} from "./actions"

export class Connection extends Component {
  knobBgColorForDarkBrands = false

  constructor(props) {
    super(props)

    this.state = {
      pushEnabled: props.applet.push_enabled,
      upgradeToPro: { showModal: false },
      showingCheckoutModal: false,
      checkoutPlanTarget: "intermediate_pro",
    }

    // change the knob color when the applet has a dark color
    if (shouldUseAlternateBrandColor(props.applet.brand_color)) {
      this.knobBgColorForDarkBrands = true
    }

    this.calculateOnResize = debounce(() => {
      this.setState({
        connectButtonMaxFontSize: this.calculateConnectButtonFontSize(),
      })
    }, 100)
  }

  componentDidMount() {
    // update state in case this applet was saved elsewhere
    // the enable state always comes from the applet payload
    if (this.props.applet.status === "enabled_for_user") {
      this.props.setAsCreated(true)
      this.props.setAsEnabled(true)
      this.props.setAsDisabled(false)
    } else if (this.props.applet.status === "disabled_for_user") {
      this.props.setAsCreated(true)
      this.props.setAsEnabled(false)
      this.props.setAsDisabled(true)
    } else {
      this.props.setAsEnabled(false)
      this.props.setAsDisabled(true)
    }

    const unsubAppletEnabled = subscribe("applet.enabled", state => {
      if (state.applet.enabled) {
        unsubAppletEnabled()
        this.props.clearSavedState()

        // communicate to everything on the page the applet has been enabled
        window.dispatchEvent(new CustomEvent("appletEnabled", { detail: { enabled: true } }))
      }
    })

    const unsubAppletDisabled = subscribe("applet.disabled", state => {
      if (state.applet.disabled) {
        unsubAppletDisabled()

        // communicate to everything on the page the applet has been disabled
        window.dispatchEvent(new CustomEvent("appletDisabled"))
      }
    })

    const unsubConnectClick = subscribe("connections.connectClicked", state => {
      // fire the event only when click to enable
      if (state.connections.connectClicked) {
        unsubConnectClick()
      }
    })

    // force that no static applets will be displayed with a connections path
    if (!this.props.dynamicConfig && window.location.href.includes("connections")) {
      window.history.pushState({}, "", `/applets/${this.props.applet.friendly_id}`)
    }

    // make the rubberband area the same color as the connection card header
    RubberBandPinchColor()

    window.addEventListener("resize", this.calculateOnResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateOnResize)
  }

  calculateConnectButtonFontSize = () => {
    if (document.body.clientWidth < 724) {
      // phone
      return 22
    } else if (document.body.clientWidth > 1444) {
      // large desktop
      return 30
    } else {
      // small desktops and tablets
      return 28
    }
  }

  state = {
    connectButtonMaxFontSize: this.calculateConnectButtonFontSize(),
  }

  executeConnectionFlow = () => {
    // don't jump to a step if the flow is finalized in the system of record
    const finalStates = ["disabled_for_user", "enabled_for_user"]
    !finalStates.includes(this.props.applet.status) && this.props.connectClicked && this.props.jumpToNextStep()
  }

  render() {
    const {
      connectClicked,
      disconnectClicked,
      dynamicConfig,
      appletEligibleForMobilePrompt,
      urls,
      applet,
      user,
      proPlusProductId,
    } = this.props
    const { status, appletDetails } = applet
    const enablement_rules = appletDetails.normalized_applet.enablement_rules
    const allowUserToConnect = this.props.user.signedIn && !enablement_rules.block_user

    const showProPrompt = () =>
      this.setState({
        upgradeToPro: { showModal: true },
      })

    const reloadDataAndConnect = appletStatus => {
      reloadSensitiveDataAfterUpgrade(applet.id).then(data => {
        this.props.updateAppletEnablementRules(data.applet.normalized_applet.enablement_rules)
        this.props.upgradeUserSubscription({
          ...this.props.user,
          ...data.me,
        })

        appletStatus === "disabled_for_user" ? this.props.reConnectApplet() : this.props.onConnectClick()
      })
    }

    const showMobileAlert = appletEligibleForMobilePrompt && applet.status === "enabled_for_user" && applet.just_enabled

    return (
      <>
        {status === "enabled_for_user" ? (
          <>
            <ConnectedApplet
              mainService={this.props.mainService}
              connectClicked={connectClicked}
              dynamicConfig={dynamicConfig}
              onDisconnectClick={this.props.disableApplet}
              appletConnected={this.props.appletConnected}
              knobBgColorForDarkBrands={this.knobBgColorForDarkBrands}
              connectButtonMaxFontSize={this.state.connectButtonMaxFontSize}
            />
          </>
        ) : status === "disabled_for_user" ? (
          <DisconnectedApplet
            mainService={this.props.mainService}
            connectClicked={connectClicked}
            disconnectClicked={disconnectClicked}
            services={this.props.services}
            applet={this.props.applet}
            dynamicConfig={dynamicConfig}
            knobBgColorForDarkBrands={this.knobBgColorForDarkBrands}
            onConnectClick={e => (allowUserToConnect ? this.props.reConnectApplet(e) : showProPrompt())}
            appletConnected={this.props.appletConnected}
            animation={this.props.animation}
            updateAnimationCol={this.props.updateAnimationCol}
            connectButtonMaxFontSize={this.state.connectButtonMaxFontSize}
          />
        ) : connectClicked && allowUserToConnect ? (
          <GrantAccess
            dynamicConfig={dynamicConfig}
            mainService={this.props.nextServiceToBeConnected}
            services={this.props.services}
            onAccessGranted={this.props.onAccessGranted}
            appletConnected={this.props.appletConnected}
            lastServiceConnected={this.props.lastServiceConnected}
            animation={this.props.animation}
            updateAnimationCol={this.props.updateAnimationCol}
            requirementsMet={this.props.requirementsMet}
            appletIsSaving={this.props.appletIsSaving}
            executeConnectionFlow={this.executeConnectionFlow}
            connectButtonMaxFontSize={this.state.connectButtonMaxFontSize}
          />
        ) : (
          <ConnectButton
            mainService={this.props.mainService}
            connected={this.props.applet.status === "enabled_for_user"}
            onConnectClick={e => {
              window.App.Utils?.logCustomDatadogAction?.("connect_button_clicked")
              !this.props.user.signedIn || allowUserToConnect ? this.props.onConnectClick(e) : showProPrompt()
            }}
            dynamicConfig={dynamicConfig}
            knobBgColorForDarkBrands={this.knobBgColorForDarkBrands}
            connectButtonMaxFontSize={this.state.connectButtonMaxFontSize}
            playEnableAnimation
          />
        )}

        {showMobileAlert && (
          <MobileAlert
            user={this.props.user}
            userDevice={this.props.userDevice}
            applet={this.props.applet}
            appStorePath={this.props.appStorePath}
            googlePlayStorePath={this.props.googlePlayStorePath}
          />
        )}

        {user.id && !allowUserToConnect && (
          <UpgradePlanModal
            onClose={() => this.setState({ upgradeToPro: { showModal: false } })}
            onUpgrade={() => reloadDataAndConnect(status)}
            proPlusProductId={proPlusProductId}
            step={enablement_rules.block_reason}
            tierRequirement={enablement_rules.minimum_tier}
            trackLocationType="connection"
            urls={urls}
            user={{
              eligibleForTrial: this.props.user.eligibleForTrial,
              eligibleTrialPeriod: this.props.user.eligibleTrialPeriod,
              isTrialing: this.props.user.isTrialing,
              subscriptionPaymentType: this.props.user.subscriptionPaymentType,
              tier: this.props.user.tier,
            }}
            visible={this.state.upgradeToPro.showModal}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  const { config, applet, user, connections, error, animation } = state
  const services = Object.values(connections.permissions)

  return {
    services,
    user,
    applet: applet.applet,
    mainService: connections.mainService,
    nextServiceToBeConnected: connections.permissions[connections.nextToBeConnected] || connections.mainService,
    connectClicked: connections.connectClicked,
    disconnectClicked: connections.disconnectClicked,
    appletConnected: applet.enabled,
    returnTo: config.returnTo,
    appStorePath: config.appStorePath,
    googlePlayStorePath: config.googlePlayStorePath,
    automation: config.automation,
    needsConfig: config.needsConfig,
    editPath: config.editPath,
    userDevice: config.userDevice,
    error,
    lastServiceConnected: connections.lastServiceConnected,
    dynamicConfig: config.dynamicConfig,
    animation: animation.collection,
    appletIsSaving: applet.appletIsSaving,
    appletEligibleForMobilePrompt: config.appletEligibleForMobilePrompt,
    myAppletURL: config.myAppletURL,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // reflect the applet status, noop but for updating the local state
    setAsCreated: status => dispatch(appletCreated(status)),
    setAsDisabled: status => dispatch(appletDisabled(status)),
    setAsEnabled: status => dispatch(appletEnabled(status)),

    // call the server
    disableApplet: () => dispatch(disableApplet()),
    reConnectApplet: () => dispatch(updateApplet()),

    // remeber, everthing is async so this is your friend if you have to find
    // if all requirements are met and you're go to connect the applet
    requirementsMet: () => dispatch(requirementsMet()),

    nextToBeConnected: service => dispatch(updateNextToBeConnected(service)),
    onConnectClick: () => dispatch(connectClicked(true)),
    onAccessGranted: service => dispatch(grantAccess(service)),
    jumpToNextStep: () => dispatch(jumpToNextStep()),
    clearSavedState: () => dispatch(clearLocalStorage()),
    updateUser: user => dispatch(setUser(user)),
    upgradeUserSubscription: user => dispatch(upgradeUserSubscription(user)),
    updateAnimationCol: state => dispatch(updateAnimationCollection(state)),
    updateAppletEnablementRules: state => dispatch(updateAppletEnablementRules(state)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Connection)

Connection.propTypes = {
  ...MobileAlert.propTypes,
  applet: PropTypes.object.isRequired,
  services: PropTypes.array,
  mainService: PropTypes.object,
  nextServiceToBeConnected: PropTypes.object,
  dynamicConfig: PropTypes.bool,
  appletConnected: PropTypes.bool,
  jumpToNextStep: PropTypes.func,
  onConnectClick: PropTypes.func,
  connectClicked: PropTypes.bool,
  disconnectClicked: PropTypes.bool,
  onAccessGranted: PropTypes.func,
  nextToBeConnected: PropTypes.func,
  returnTo: PropTypes.string,
  requestLogin: PropTypes.func,
  reConnectApplet: PropTypes.func,
  error: PropTypes.object,
  automation: PropTypes.bool,
  needsConfig: PropTypes.bool,
  editPath: PropTypes.string,
  lastServiceConnected: PropTypes.object,
  setAsDisabled: PropTypes.func,
  disableApplet: PropTypes.func,
  animation: PropTypes.object,
  updateAnimationCol: PropTypes.func,
  setAsCreated: PropTypes.func,
  setAsEnabled: PropTypes.func,
  requirementsMet: PropTypes.func,
  clearSavedState: PropTypes.func,
  updateUser: PropTypes.func,
  appletIsSaving: PropTypes.bool,
  appletEligibleForMobilePrompt: PropTypes.bool,
}
