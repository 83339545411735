import { updateApplet } from "shared/components/connection_card/api.js"

const PushNotification = (appletId:string, attribute:string) => {
  document.querySelector(`button[data-switch-target=${attribute}`)?.addEventListener("click", (event) => {
    const switchUi = event.target.previousElementSibling
    const currentValue = !switchUi.classList.contains("disabled")
    const newValue = !currentValue

    updateApplet({appletId: `${appletId}`, [attribute]: newValue}).then(response => {
      if (response?.errors.length == 0) {
        switchUi.classList.toggle("disabled")
      } else {
        window.appendFlash("Error updating your Applet's notification. Try again soon.", "danger")
      }
    })
  })
}

export default PushNotification
