export default function initializeFacebook() {
  const appId = document.querySelector("[data-fb-app-id]")?.dataset?.fbAppId
  if (!appId) return

  window.fbAsyncInit = function () {
    FB.init({
      appId,
      version: "v2.9",
      cookie: true,
    })
  }

  window.App.loadScriptAsync("//connect.facebook.net/en_US/sdk.js", "facebook-jssdk", { crossorigin: "anonymous" }, 1)
    .catch(() => {
      window.App.env === "development" && console.error("Failed to load Facebook SDK. Fallback to direct anchor link")
    })
}
