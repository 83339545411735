import { Component } from "react"
import PropTypes from "prop-types"
import includes from "lodash/includes"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import classnames from "classnames"

import { patch } from "shared/lib/api"
import { assignWithBustedCache } from "shared/lib/utils"
import StoredFieldsCollection from "app/components/applet_stored_fields_form/stored_fields_collection"
import Modal from "shared/components/modal"
import Switch from "shared/components/switch"
import FilterIcon from "shared/components/icons/filter_icon"
import appletOrConnection from "shared/lib/use_applet_or_connection"

import colors from "foundation/_colors_export.scss?variables"

const GroupHeader = ({ config, toggle }) => {
  const featureDisabled = toggle.visible && !toggle.value
  const featureHeaderTextClasses = classnames("feature-header-text", {
    "with-toggle": toggle.visible,
    disabled: featureDisabled,
  })
  const featureDescriptionClasses = classnames("feature-description", {
    disabled: featureDisabled,
  })

  return (
    <>
      <div className="feature-header">
        <div className={featureHeaderTextClasses}>
          {config.icon && <img src={`/value-prop-icons/${config.icon}.svg`} />}
          {config.title && <h4 className="feature-title">{config.title}</h4>}
        </div>
        {toggle.visible && (
          <Switch
            backgroundColor={colors.connectionSwitchBackground}
            circleColor={colors.primaryTextColor}
            backgroundColorEnabled={colors.connectionSwitchBackgroundEnable}
            circleColorEnabled={colors.primaryWhite}
            onChange={toggle.onChange}
            value={toggle.value}
          />
        )}
      </div>
      {config.description && (
        <p className={featureDescriptionClasses}>{config.description}</p>
      )}
    </>
  )
}

GroupHeader.propTypes = {
  config: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  toggle: PropTypes.shape({
    visible: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }),
}

export default class ConnectionStoredFieldsForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formWasEnabled: false,
      fieldsReady: {},
      errors: {},
      canAsyncRetry: false,
      applet: props.applet,
      disabledGroups: this.props.disabled_slugs.reduce((acc, curr) => {
        return { ...acc, [curr]: true }
      }, {}),
      showWarning: false,
      permissions: props.applet.permissions.map(permission => {
        const availableLiveChannels = props.applet.channels.find(
          channel => channel.module_name === permission.service_id
        ).live_channels
        const defaultLiveChannel =
          availableLiveChannels.find(lc => !lc.offline) ||
          availableLiveChannels[0]

        return {
          ...permission,
          live_channel_id: defaultLiveChannel?.id,
        }
      }),
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.onError = this.onError.bind(this)
    this.onInvalidResolution = this.onInvalidResolution.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.enableGroup = this.enableGroup.bind(this)
    this.disableGroup = this.disableGroup.bind(this)
    this.fieldKey = this.fieldKey.bind(this)
    this.updateServiceList = this.updateServiceList.bind(this)
    this.configType = appletOrConnection(this.state.applet)
  }

  componentDidMount() {
    if (this.isFormEnabled(this.props, this.state)) {
      this.setState({ formWasEnabled: true }, this.props.onFormEnabled)
    } else {
      this.setState({ formWasEnabled: false }, this.props.onFormDisabled)
    }

    if (this.props.error) {
      this.onError(this.props.error)
    }
  }

  componentDidUpdate() {
    const formIsEnabled = this.isFormEnabled(this.props, this.state)

    if (!this.state.formWasEnabled && formIsEnabled) {
      this.setState({ formWasEnabled: true }, this.props.onFormEnabled)
    } else if (this.state.formWasEnabled && !formIsEnabled) {
      this.setState({ formWasEnabled: false }, this.props.onFormDisabled)
    }
  }

  onStoredFieldReady = fieldOwner => {
    this.setState(state => {
      return {
        fieldsReady: Object.assign(state.fieldsReady, { [fieldOwner]: true }),
      }
    })
  }

  onInvalidResolution(error, fieldName, field) {
    let errors = {}
    // TODO: update this once we get new error formats
    errors[`/fields/${field.name}`] = "Please reconnect your service"

    let asyncFieldTypes = [
      "collection_select",
      "double_collection_select",
      "checkbox_multi",
    ]
    this.setState({
      errors,
      canAsyncRetry: includes(asyncFieldTypes, field.field_subtype),
    })
    window.appendFlash("Error accessing the service", "danger")
  }

  fieldKey(field) {
    // If dynamic applet doesn't have configuration, then it's before migration.
    // Custom field should only have field.owner.
    if (this.props.programmable && !isEmpty(this.props.configuration) && field.configuration_slug) {
      const separator = field.owner.match(/^\//) ? "" : "/"
      return `/${field.configuration_slug}${separator}${field.owner}`
    } else {
      return field.owner
    }
  }

  async onFieldChange(field, value, automated) {
    this.setState(
      state => {
        let fields = state.fields || {}
        const key = this.fieldKey(field)
        let owner = fields[key] || {}

        owner[field.name] = value

        fields = {
          ...fields,
          [key]: owner,
        }

        if (field.field_subtype === "service_account_select") {
          let temporaryPermissions = state.permissions
          temporaryPermissions.find(p => p.id === field.owner).live_channel_id = value

          state.permissions = temporaryPermissions
        }

        state.fields = fields

        return state
      },
      () => this.props.onFieldChanged && this.props.onFieldChanged(automated)
    )
  }

  expandConfigurationGroupWithError = () => {
    if (
      this.props.programmable &&
      this.props.configuration &&
      this.state.validationErrors &&
      this.state.validationErrors.stored_fields
    ) {
      Object.keys(this.props.configuration).map(slug => {
        if (this.state.validationErrors.stored_fields[slug]) {
          this.enableGroup(slug)
        }
      })
    }
  }

  updateServiceList = (channelModuleName, liveChannels) => {
    let temporaryChannels = this.state.applet.channels
    temporaryChannels.find(
      p => p.module_name === channelModuleName
    ).live_channels = liveChannels

    this.setState({
      ...this.state,
      applet: {
        ...this.state.applet,
        channels: temporaryChannels,
      },
    })
  }

  onSubmit = (name, pushEnabled) => {
    this.allDisabled()
      ? this.setState({ showWarning: true })
      : this.saveChanges(name, pushEnabled)
  }

  saveChanges = (name, pushEnabled) => {
    let storedFieldsEnabled = {}
    Object.keys(this.state.fields).map(field_name => {
      if (!this.state.disabledGroups[field_name.split("/")[1]]) {
        storedFieldsEnabled[field_name] = {
          ...this.state.fields[field_name],
          _live_channel_id: this.state.permissions.find(
            p => p.id === field_name
          )?.live_channel_id, // not set for connections bc it doen't have live_channel_id
        }
      }
    })
    const enableProps = {
      connection: {
        id: this.state.applet.id,
        stored_fields: storedFieldsEnabled,
        filter_code: this.props.filterCode,
        metadata: {
          configurations: Object.keys(this.props.configuration).map(slug => {
            return {
              slug,
              disabled: !!this.state.disabledGroups[slug],
            }
          }),
        },
      },
    }

    if (name) enableProps.connection.name = name
    if (pushEnabled !== null) enableProps.connection.push_enabled = pushEnabled
    return patch(this.props.submitUrl, enableProps)
      .then((res) => {
        this.props.onDoneSaving(false)
        // SDK Connections will get redirected in api_controller
        this.props.showSuccessMessage && window.appendFlash("Changes saved")

        window.App.Utils?.logCustomDatadogAction?.("applet_enabled", { applet_id: this.state.applet.id })

        if (res.location) {
          assignWithBustedCache(res.location)
        } else if (this.props.connectionURL) {
          assignWithBustedCache(this.props.connectionURL)
        }
      })
      .catch(this.onError)
  }

  onError = async result => {
    const err = await result.response

    this.props.onDoneSaving(true)
    if (err && err.redirect_to) {
      return window.location.assign(err.redirect_to)
    }

    let message = `There was a problem saving this ${this.configType}`
    const details = get(err, "original.details", [])

    if (details.length > 0) {
      message = `${message} — see the errors below`
    }

    window.appendFlash(message, "danger")
    if (err && err.error) {
      this.setState({ errors: err.error })
    }

    if (err && err.validation_errors) {
      this.setState({ validationErrors: err.validation_errors }, this.expandConfigurationGroupWithError)
    }

    this.props.clearLocalStorage()
  }

  isFormEnabled(props, state) {
    const enabledFields = [].concat(
      ...Object.entries(props.storedFields)
        .filter(pair => !state.disabledGroups[pair[0]])
        .map(pair => pair[1])
    )

    return Object.keys(state.fieldsReady).length === enabledFields.length
  }

  enableGroup(group) {
    let disabledGroups = Object.assign({}, this.state.disabledGroups)
    delete disabledGroups[group]
    this.setState({ disabledGroups })
  }

  disableGroup(group) {
    let fieldsReady = Object.assign({}, this.state.fieldsReady)
    let disabledGroups = Object.assign({}, this.state.disabledGroups)

    disabledGroups[group] = true
    this.props.storedFields[group].forEach(
      field => delete fieldsReady[`${field.owner}.${field.name}`]
    )

    this.setState({ disabledGroups, fieldsReady })
  }

  allDisabled() {
    return Object.keys(this.props.storedFields).every(
      group => group === "#global" || !!this.state.disabledGroups[group]
    )
  }

  render() {
    const config = this.props.configuration || {}
    const storedFieldConfigs = Object.keys(
      this.props.storedFields || {}
    ).reduce((obj, k) => (config[k] ? obj : { ...obj, [k]: null }), {})

    // Required both to make sure that configurations appear in their proper
    // order, and that we don't miss stored fields on config-less connections.
    // TODO: this should be replaced with just `this.props.configuration` after
    // migrating config-less connections.
    const featureGroups = Object.keys({
      "#global": {},
      ...(this.props.configuration || {}),
      ...storedFieldConfigs,
    })

    const filterCodeCta = this.props.showFilterCta ? (
      <div className="filter-cta" key="filter">
        <span>
          <FilterIcon />
          <span className="title">Filter code</span>
        </span>
        <span className="edit" onClick={this.props.toggleShowFilterCode}>
          Edit
        </span>
      </div>
    ) : null

    return (
      <>
        <Modal
          show={this.state.showWarning}
          closeListener={() => this.setState({ showWarning: false })}
          includeCloseX={false}
        >
          <p>
            You&rsquo;re about to save this connection, but you’ve disabled all
            of its features.
          </p>
          <p>Are you sure you want to continue?</p>
          <div className="button-container">
            <button
              onClick={() => this.setState({ showWarning: false })}
              className="button-tertiary button-outlined"
            >
              Cancel
            </button>
            <div className="spacer" />
            <button onClick={this.saveChanges} className="button-tertiary">
              Continue
            </button>
          </div>
        </Modal>
        <form onSubmit={this.onSubmit}>
          <section className="stored_fields">
            {featureGroups.map(slug => {
              if (!this.props.storedFields[slug]) {
                return null
              }

              const config =
                (this.props.configuration && this.props.configuration[slug]) ||
                {}
              const hideStoredFieldsCollection =
                this.props.programmable && !!this.state.disabledGroups[slug]
              const canDisable =
                this.props.programmable &&
                slug &&
                slug !== "#global" &&
                !config.required
              const toggleFeature = slug => {
                return () => {
                  if (this.state.disabledGroups[slug]) {
                    this.enableGroup(slug)
                  } else {
                    this.disableGroup(slug)
                  }
                }
              }

              return (
                <div key={slug} className="fieldset">
                  <GroupHeader
                    config={config}
                    toggle={{
                      visible: !!canDisable,
                      value: !this.state.disabledGroups[slug],
                      onChange: toggleFeature(slug),
                    }}
                  />
                  {!hideStoredFieldsCollection && (
                    <StoredFieldsCollection
                      configurationSlug={slug}
                      applet={this.state.applet}
                      permissions={this.state.permissions}
                      fields={this.props.storedFields[slug]}
                      includeHeader={!this.props.programmable}
                      ingredientsMetadata={this.props.ingredientsMetadata}
                      onInvalidResolution={this.onInvalidResolution}
                      onFieldChange={this.onFieldChange}
                      onStoredFieldReady={this.onStoredFieldReady}
                      validationErrors={this.state.validationErrors}
                      canAsyncRetry={this.state.canAsyncRetry}
                      useInlineErrors={this.props.useInlineErrors}
                      includeDescriptions={this.props.includeDescriptions}
                      filterCodeCta={filterCodeCta}
                      useIngredientsForAppletOwners={
                        this.props.useIngredientsForAppletOwners
                      }
                      hideIngredientNamespaces={
                        this.props.hideIngredientNamespaces
                      }
                      updateFormEnabled={status => {
                        status === true
                          ? this.props.onFormEnabled()
                          : this.props.onFormDisabled()
                      }}
                      userAllowMultipleLiveChannels={
                        this.props.userAllowMultipleLiveChannels
                      }
                      proFeatureGate={this.props.proFeatureGate}
                      multiServiceAccounts={{
                        connectionFinishedUrl: this.props.connectionFinishedUrl,
                        updateServiceList: this.updateServiceList,
                      }}
                      multipleLiveChannelsNewBadgeVisibility={
                        this.props.multipleLiveChannelsNewBadgeVisibility
                      }
                      hideMultipleLiveChannelsNewBadge={
                        this.props.hideMultipleLiveChannelsNewBadge
                      }
                    />
                  )}
                </div>
              )
            })}
          </section>
        </form>
      </>
    )
  }
}

ConnectionStoredFieldsForm.propTypes = {
  storedFields: PropTypes.objectOf(PropTypes.array),
  configuration: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      required: PropTypes.bool,
      icon: PropTypes.string,
    })
  ),
  disabled_slugs: PropTypes.arrayOf(PropTypes.string),
  ingredientsMetadata: PropTypes.object,
  applet: PropTypes.object,
  submitUrl: PropTypes.string,
  connectionURL: PropTypes.string,
  connectionFinishedUrl: PropTypes.string,
  includeDescriptions: PropTypes.bool,
  useInlineErrors: PropTypes.bool,
  onFormEnabled: PropTypes.func,
  onFormDisabled: PropTypes.func,
  clearLocalStorage: PropTypes.func,
  showSuccessMessage: PropTypes.bool,
  programmable: PropTypes.bool,
  useIngredientsForAppletOwners: PropTypes.bool,
  hideIngredientNamespaces: PropTypes.bool,
  error: PropTypes.object,
  showFilterCta: PropTypes.bool,
  toggleShowFilterCode: PropTypes.func,
  filterCode: PropTypes.string,
  onFieldChanged: PropTypes.func,
  onDoneSaving: PropTypes.func,
  userAllowMultipleLiveChannels: PropTypes.bool,
  multipleLiveChannelsNewBadgeVisibility: PropTypes.bool,
  hideMultipleLiveChannelsNewBadge: PropTypes.func,
  proFeatureGate: PropTypes.func,
}

ConnectionStoredFieldsForm.defaultProps = {
  disabled_slugs: [],
  includeDescriptions: true,
  showSuccessMessage: true,
  useInlineErrors: false,
  useIngredientsForAppletOwners: false,
  userAllowMultipleLiveChannels: false,
  onFormEnabled: () => {},
  onFormDisabled: () => {},
  onDoneSaving: () => {},
}
