import { useState, useRef, useEffect } from "react"
import Modal from "shared/components/modal"

import "./generator_modal.scss"

interface Props {
  askAItoGenerateFilterCode: object
  onClose: object
  prompt: string
  setPrompt: object
  showAIGenerator: boolean
}

const IAGeneratorModal = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const { askAItoGenerateFilterCode, onClose, prompt, setPrompt, showAIGenerator } = props
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const onClick = () => {
    setLoading(true)
    askAItoGenerateFilterCode()
  }

  useEffect(() => {
    if (loading && !showAIGenerator) {
      setLoading(false)
    }
  }, [showAIGenerator])

  return (
    <div styleName={`modal-container`}>
      <Modal show={showAIGenerator} closeListener={onClose} includeCloseX={false}>
        <div className="mb4" styleName="helper-text">
          AI has access to the foundation of your Applet automation (trigger, queries, and actions). Provide a clear
          prompt with the desired outcome to generate the filter code.
        </div>

        <div>
          <textarea
            ref={textAreaRef}
            placeholder="Ex: If a new Calendly event is added, then create event in Google Calendar."
            rows={3}
            maxLength={500}
            value={prompt}
            onKeyDown={evt => {
              if (evt.keyCode === 13) {
                evt.preventDefault()

                onClick()
              }
            }}
            onChange={evt => {
              setPrompt(evt.target.value)
            }}
          />
        </div>

        <div className="action-buttons-container mt4">
          <button className="button-tertiary" onClick={onClick} disabled={prompt.length == 0 || loading}>
            {loading ? "Asking to AI..." : "Generate code"}
          </button>
          <button className="button-tertiary button-cancel-link" onClick={onClose}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default IAGeneratorModal
