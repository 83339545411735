import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroll-component"
import parse from "html-react-parser"

import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"
import { getUserApplets, getUserLiveApplets, getMyAppletsTotal } from "shared/lib/graphql"
import classnames from "classnames"
import EmptyStateRecommendations from "./empty_state_recommendations"
import Search from "./search"
import AppletCard from "./applet_card"
import Upsell from "./upsell"
import Spinner from "shared/components/spinner"
import SearchIndex from "shared/lib/search_index"
import { sortByCreatedAt } from "../shared/utils"
import EmptyStateCta from "shared/components/empty_state/index.tsx"
import ErrorIcon from "images/icons/icon-error.svg"
import "./my_applets.scss"

export default function MyApplets({ archivePath, quota, userLogin, createUrl }) {
  const PAGE_SIZE = 24

  const [filterApplets, setAppletsFilter] = useState("all")
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [appletsIndex] = useState(new SearchIndex())
  const [appletOffset, setAppletOffset] = useState(0)
  const [foundAllLiveApplets, setFoundAllLiveApplets] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    getNextPage()
  }, [])

  useEffect(() => {
    if (foundAllLiveApplets) {
      getNextPage()
    }
  }, [foundAllLiveApplets])

  const [activeLiveApplets, setActiveLiveApplets] = useState([])
  const [inactiveLiveApplets, setInactiveLiveApplets] = useState([])
  const [inactiveCreatedApplets, setInactiveCreatedApplets] = useState([])
  const [publishedApplets, setPublishedApplets] = useState(null)
  const [myAppletsTotal, setMyAppletsTotal] = useState(null)

  useEffect(() => {
    getMyAppletsTotal().then(total => setMyAppletsTotal(total))
  }, [])

  useEffect(() => {
    const liveAppletIds = new Set([...activeLiveApplets, ...inactiveLiveApplets].map(sa => sa.id))
    const newCreatedApplets = inactiveCreatedApplets.filter(ca => !liveAppletIds.has(ca.id))

    if (newCreatedApplets.length !== inactiveCreatedApplets.length) {
      setInactiveCreatedApplets(newCreatedApplets)
    }
  }, [inactiveLiveApplets, activeLiveApplets, inactiveCreatedApplets])

  useEffect(() => {
    let newSearchResults = null
    const applets = [...activeLiveApplets, ...inactiveLiveApplets, ...inactiveCreatedApplets]

    if (filterApplets === "all") {
      newSearchResults = applets
    } else if (filterApplets === "published_by_me") {
      newSearchResults = publishedApplets
    }

    if (searchTerm) {
      newSearchResults = newSearchResults.filter(applet => appletsIndex.search(searchTerm).includes(applet.id))
    }

    setSearchResults(newSearchResults)

    // Get next page if we are filtering, to make sure we aren't missing anything
    if (hasMore && (filterApplets !== "all" || searchTerm)) {
      getNextPage()
    }
  }, [searchTerm, filterApplets, activeLiveApplets, inactiveLiveApplets, inactiveCreatedApplets, publishedApplets])

  const logFailure = e => {
    setActiveLiveApplets([])
    setInactiveLiveApplets([])
    setInactiveCreatedApplets([])
    setPublishedApplets([])
    setHasMore(false)

    rejectedXHRPromiseLogger()
  }

  const getNextPage = () => {
    if (!hasMore) {
      return
    }

    const addServiceName = applet => ({
      ...applet,
      service_names: applet.channels.map(ch => ch.name),
    })

    let promise = null

    if (foundAllLiveApplets) {
      promise = getUserApplets(userLogin, PAGE_SIZE, appletOffset).then(({ applets: personalApplets }) => {
        if (personalApplets.length < PAGE_SIZE) {
          setHasMore(false)
        }

        const liveAppletIds = new Set([...activeLiveApplets, ...inactiveLiveApplets].map(sa => sa.id))
        const createdApplets = (personalApplets || []).map(a => a.normalized_applet).filter(ma => !ma.archived)
        const newInactiveCreatedApplets = createdApplets
          .map(applet => {
            return {
              ...applet,
              status: "disabled_for_user", // hack to show connect button as disabled for applets without live applet
              service_names: applet.channels.map(ch => ch.name),
            }
          })
          .filter(ca => !liveAppletIds.has(ca.id))

        setInactiveCreatedApplets([...inactiveCreatedApplets, ...newInactiveCreatedApplets])

        const newPublishedApplets = sortByCreatedAt(
          newInactiveCreatedApplets?.filter(c => {
            return c.author === userLogin && c.published === true
          })
        )

        setPublishedApplets([...(publishedApplets || []), ...(newPublishedApplets || [])])

        newInactiveCreatedApplets?.forEach(doc => {
          appletsIndex.add(doc.id, doc.name, [...doc.service_names, ...(doc.description || "").split(" ")])
        })

        setAppletOffset(appletOffset + PAGE_SIZE)

        return [...activeLiveApplets, ...inactiveLiveApplets, ...inactiveCreatedApplets, ...newInactiveCreatedApplets]
      })
    } else {
      promise = getUserLiveApplets(PAGE_SIZE, appletOffset).then(({ live_applets }) => {
        if (live_applets.length < PAGE_SIZE) {
          setFoundAllLiveApplets(true)
          setAppletOffset(0)
        } else {
          setAppletOffset(appletOffset + PAGE_SIZE)
        }

        const liveApplets = [
          ...(live_applets || [])
            .map(la => la.applet.normalized_applet)
            .filter(ma => !(ma.author === userLogin && ma.archived)),
        ].map(addServiceName)

        const newActiveLiveApplets = sortByCreatedAt(liveApplets.filter(la => Object.is(la.status, "enabled_for_user")))
        setActiveLiveApplets([...activeLiveApplets, ...newActiveLiveApplets])

        const newInactiveLiveApplets = sortByCreatedAt(
          liveApplets.filter(la => !Object.is(la.status, "enabled_for_user"))
        )
        setInactiveLiveApplets([...inactiveLiveApplets, ...newInactiveLiveApplets])

        const newApplets = [...newActiveLiveApplets, ...newInactiveLiveApplets]

        const newPublishedApplets = newApplets?.filter(c => {
          return c.author === userLogin && c.published === true
        })
        setPublishedApplets([...(publishedApplets || []), ...(newPublishedApplets || [])])

        newApplets.forEach(doc => {
          appletsIndex.add(doc.id, doc.name, [...doc.service_names, ...(doc.description || "").split(" ")])
        })

        return [...activeLiveApplets, ...newActiveLiveApplets, ...inactiveLiveApplets, ...newInactiveLiveApplets]
      })
    }

    return promise.catch(logFailure)
  }

  if (myAppletsTotal === 0) {
    return <EmptyStateRecommendations createPath={createUrl} />
  }

  const publishingLearnMoreCta = (
    <p>
      <a
        target="_blank"
        className="help_link link-inline"
        href="https://help.ifttt.com/hc/articles/4417802100763"
        rel="noreferrer"
      >
        Learn more
      </a>{" "}
      about publishing Applets for anyone to use.
    </p>
  )

  let counter
  if (myAppletsTotal !== null) {
    if (quota.total > 0) {
      counter = `All (${myAppletsTotal} of ${quota.total})`
    } else {
      counter = `All (${myAppletsTotal})`
    }
  } else {
    counter = "All"
  }

  const remainingQuota = quota.total - myAppletsTotal
  const negativeQuotaTarget = myAppletsTotal > 20 ? quota.proPlusSubscriptionUrl : quota.proSubscriptionUrl
  const negativeQuotaWarning = quota.tier == "free" && remainingQuota < 0

  return (
    <>
      <h1>My Applets</h1>

      <Search onSearch={setSearchTerm} value={searchTerm} />

      <div styleName="filters" role="tablist">
        <span
          role="tab"
          aria-label="All Applets"
          styleName={classnames({ active: filterApplets === "all" })}
          onClick={() => setAppletsFilter("all")}
        >
          {counter}
        </span>

        <span
          role="tab"
          aria-label="Applets published by me"
          styleName={classnames({
            active: filterApplets === "published_by_me",
          })}
          onClick={() => setAppletsFilter("published_by_me")}
        >
          {publishedApplets?.length > 0 ? `Published (${publishedApplets.length})` : "Published"}
        </span>

        <span role="tab" aria-label="Applets archive">
          <a href={archivePath}>Archive</a>
        </span>
        <span role="tab" aria-label="Upgrade to Pro" styleName="upsell_link">
          <Upsell myAppletsTotal={myAppletsTotal} {...quota} />
        </span>
      </div>

      <section className="dashboard-applets">
        <div>
          {filterApplets == "published_by_me" && searchResults.length > 0 ? publishingLearnMoreCta : null}
          {searchResults.length > 0 ? (
            <>
              {negativeQuotaWarning && (
                <div className="txt-body-3" styleName="over_quota">
                  <span>{parse(ErrorIcon)}</span>
                  <p>
                    Free IFTTT accounts are now limited to two Applets. Archive {Math.abs(remainingQuota)} Applet
                    {Math.abs(remainingQuota) === 1 ? "" : "s"} below, or{" "}
                    <a href={negativeQuotaTarget}>upgrade to unlock more Applets</a>.
                  </p>
                </div>
              )}
            </>
          ) : filterApplets == "published_by_me" && publishedApplets.length == 0 ? (
            <EmptyStateCta
              heading="No Published Applets."
              copy="Select an Applet you've created or create a new Applet to get started."
              cta="Publish an Applet"
              ctaUrl={createUrl}
            />
          ) : !hasMore ? (
            <EmptyStateCta
              heading="No results found..."
              copy="Looks like we couldn't find any Applet matches. Please try again."
            />
          ) : null}
          <InfiniteScroll
            dataLength={searchResults.length}
            next={getNextPage}
            hasMore={hasMore}
            loader={
              <div styleName="spinner-container">
                <Spinner
                  config={{
                    position: "relative",
                    top: "inherit",
                  }}
                />
              </div>
            }
          >
            <ul className="web-applet-cards">
              {searchResults.map(applet => (
                <AppletCard key={applet.id} applet={applet} />
              ))}
            </ul>
          </InfiniteScroll>
        </div>
      </section>

      <div className="breath-space" />
    </>
  )
}

MyApplets.propTypes = {
  archivePath: PropTypes.string.isRequired,
  quota: PropTypes.shape({
    proPlusSubscriptionUrl: PropTypes.string.isRequired,
    proSubscriptionUrl: PropTypes.string.isRequired,
    tier: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    trialing: PropTypes.bool.isRequired,
  }),
  userLogin: PropTypes.string.isRequired,
  createUrl: PropTypes.string.isRequired,
}
