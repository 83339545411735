import { lazy, Suspense, useState, useEffect } from "react"
import PropTypes from "prop-types"
import Mustache from "mustache"

import { buildFilterContextDataForDIY } from "shared/components/filter_code/build_filter_context_data"
import ErrorMessageForField from "./error_message"
import { generateFilterCodeWithAI, loadFilterCodeGenerators, validateFilterCode } from "./api"

import generateApiList from "./filter_code/api_list.tsx"
import FilterCodeIngredientListbox from "./filter_code/ingredient_listbox.tsx"
import GeneratorModal from "./filter_code/generator_modal.tsx"
import IAGeneratorModal from "./filter_code/ia_generator_modal.tsx"

import trim from "lodash/trim"

import "./filter_code.scss"

const MonacoEditor = lazy(() => import("shared/components/monaco_editor"))

export default function FilterCode({
  contextTemplate,
  eligibleForIAFilterCodeSuggestion,
  selectedTrigger,
  selectedQueries,
  selectedActions,
  filterCode,
  updateFilterCode,
}) {
  const contextData = buildFilterContextDataForDIY({
    selectedTrigger,
    selectedQueries,
    selectedActions,
  })
  const apiList = generateApiList(contextData)

  const filterCodePlaceholder = `// Add your code here. All actions will run unless you explicitly skip them.
// Quick tips!
// Auto-complete is on. Start typing to see ingredient options.
// Hover over any ingredient to see the variable type and an example.
// F1 to list keyboard shortcuts.
// TypeScript v2.92
`
  const [code, updateCode] = useState(filterCode || filterCodePlaceholder)
  const [prompt, setPrompt] = useState("")
  const [generators, updateGenerators] = useState([])
  const [selectedGenerator, updateSelectedGenerator] = useState(null)
  const [showAIGenerator, updateShowAIGenerator] = useState(false)
  const [errors, updateErrors] = useState([])
  const [validFilterCode, updateValidFilterCode] = useState(false)
  const [submitTriggered, updateSubmitTriggered] = useState(false)

  useEffect(() => {
    loadFilterCodeGenerators(
      [selectedTrigger.id],
      selectedQueries.map(q => q["id"]),
      selectedActions.map(a => a["id"])
    ).then(result => {
      updateGenerators(result)
    })
  }, [])

  useEffect(() => {
    if (errors.length) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [errors])

  useEffect(() => {
    const trimmedCode = trim(code)
    if (trimmedCode === trim(filterCodePlaceholder)) {
      updateValidFilterCode(false)
    } else {
      updateValidFilterCode(true)
    }
  }, [code])

  const buttonText = () => {
    let text = "Add filter"

    if (filterCode === "") {
      text = submitTriggered ? "Adding filter..." : "Add filter"
    } else {
      text = submitTriggered ? "Updating filter..." : "Update filter"
    }

    return text
  }

  const onClick = () => {
    updateSubmitTriggered(true)

    if (trim(code) === "") {
      updateFilterCode(null)
      return
    }

    validateFilterCode({
      filter_code: code,
      trigger: selectedTrigger,
      queries: selectedQueries,
      actions: selectedActions,
    })
      .then(({ errors }) => {
        if (errors.length) {
          updateErrors(errors)
          updateSubmitTriggered(false)
        } else {
          updateFilterCode(code)
        }
      })
      .catch(() => {
        updateErrors([{ message: "Unable to validate filter code" }])
        updateSubmitTriggered(false)
      })
  }

  const askAItoGenerateFilterCode = () => {
    generateFilterCodeWithAI({
      user_description: prompt,
      trigger: selectedTrigger,
      queries: selectedQueries,
      actions: selectedActions,
    })
      .then(result => {
        if (result.value) {
          navigator.clipboard.writeText(result.value)
          window.appendFlash("Copied to clipboard", "success")
          setPrompt("")
          updateShowAIGenerator(false)
        } else {
          console.log(result.errrors)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div styleName="filter-code">
      <section styleName="left-panel">
        {errors.map(e => (
          <ErrorMessageForField key={e.message} errors={e} field="message" />
        ))}
        <Suspense fallback="Loading...">
          <MonacoEditor
            positionAbsolute={false}
            // MonacoEditor on filter code uses all available space in their zone. It's no possible to use height 100% because it's expected a blocked height for
            // component to calculate the JS height. 182px is the size from top (header + extra space) to the component.
            height="calc(100vh - 182px)"
            defaultValue={code}
            onChange={updateCode}
            typescriptContext={Mustache.render(contextTemplate, contextData)}
          />
        </Suspense>
      </section>

      <section styleName="right-panel">
        <div styleName="cta" className="filter-code-cta">
          <button className="button-tertiary" disabled={!validFilterCode || submitTriggered} onClick={onClick}>
            {buttonText()}
          </button>
        </div>

        <FilterCodeIngredientListbox
          apiList={apiList["Triggers"]}
          name="Triggers"
          serviceList={[selectedTrigger.service]}
        />

        <FilterCodeIngredientListbox
          apiList={apiList["Queries"]}
          name="Queries"
          serviceList={selectedQueries.map(q => q.service)}
        />

        <FilterCodeIngredientListbox
          apiList={apiList["Actions"]}
          name="Actions"
          serviceList={selectedActions.map(q => q.service)}
        />

        <FilterCodeIngredientListbox apiList={apiList["More"]} name="More" />

        {generators && (
          <section styleName="generators">
            <h4 className="mt4 mb2">Code generators</h4>
            <ul>
              {eligibleForIAFilterCodeSuggestion && (
                <li className="mb2">
                  <a className="link-standalone" onClick={() => updateShowAIGenerator(true)}>
                    AI code generator
                  </a>
                </li>
              )}
              {generators.map(generator => (
                <li key={generator.id} className="mb2">
                  <a className="link-standalone" onClick={() => updateSelectedGenerator(generator.id)}>
                    {generator.title}
                  </a>
                </li>
              ))}
            </ul>
            {selectedGenerator && (
              <GeneratorModal
                generator={generators.find(g => g.id === selectedGenerator)}
                onClose={() => updateSelectedGenerator(null)}
              />
            )}
            {eligibleForIAFilterCodeSuggestion && (
              <IAGeneratorModal
                askAItoGenerateFilterCode={askAItoGenerateFilterCode}
                onClose={() => updateShowAIGenerator(false)}
                prompt={prompt}
                setPrompt={setPrompt}
                showAIGenerator={showAIGenerator}
              />
            )}
          </section>
        )}
      </section>
    </div>
  )
}

FilterCode.propTypes = {
  contextTemplate: PropTypes.string.isRequired,
  eligibleForIAFilterCodeSuggestion: PropTypes.bool,
  selectedTrigger: PropTypes.object,
  selectedQueries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterCode: PropTypes.string,
  updateFilterCode: PropTypes.func.isRequired,
}

FilterCode.defaultProps = {
  eligibleForIAFilterCodeSuggestion: false,
  filterCode: "",
}
