import { liveConfigurationUpdate, parseGraphValidationErrors } from "shared/lib/graphql"

const DynamicConfigurationToggle = (editPath:string, connectionId:string) => {
  const valuePropositionsWrapper = document.querySelector(".value-propositions")
  const allConfigurations = valuePropositionsWrapper.querySelectorAll(".proposition[data-slug]")

  // IFE requires all configurations to be present, required or not config.
  const buildMetadata = (clickedButton) => {
    return {
      configurations: Array.from(allConfigurations).map(proposition => {
        let currentValue = proposition.querySelector(".switch-ui")?.classList.contains("disabled")

        // Required options doesn't have switch component
        if (currentValue === undefined) {
          currentValue = false
        }

        // Toggle the value of the clicked button
        if (proposition.getAttribute("data-slug") == clickedButton.getAttribute("data-switch-target")) {
          currentValue = !currentValue
        }

        return {
          slug: proposition.getAttribute("data-slug"),
          disabled: currentValue
        }
      })
    }
  }

  const updateConfiguration = (event) => {
    const clickedButton = event.target
    const metadata = buildMetadata(clickedButton)

    liveConfigurationUpdate(connectionId, metadata)
      .then(data => {
        if (data.errors) {
          window.location.assign(
            `${editPath}?error=${encodeURIComponent(
              JSON.stringify(parseGraphValidationErrors(data.errors))
            )}`
          )
        } else {
          clickedButton.previousElementSibling.classList.toggle("disabled")
          window.appendFlash("Connection successfully updated")
        }
      })
      .catch(() =>
        window.appendFlash(
          "There was an error updating this connection. Please try agian later.",
          "danger"
        )
      )
  }

  valuePropositionsWrapper?.querySelectorAll("button[data-switch-target]").forEach((button) => {
    button.addEventListener("click", updateConfiguration)
  })
}

export default DynamicConfigurationToggle
