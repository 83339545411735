import {
  appletCreated,
  appletDisabled,
  appletEnabled,
  appletUpdated,
  setApplet,
  appletIsSaving,
  connectClicked,
  disconnectClicked,
  resetConnectClicked,
} from "../actions"

const initialState = {
  enabled: false,
  created: false,
  disabled: false,
  liveChannelCreated: false,
  applet: null,
  appletIsSaving: false,
}

import appletOrConnection from "shared/lib/use_applet_or_connection"

export const createApplet = () => (dispatch, getState, api) => {
  const {
    applet: { applet },
    config,
  } = getState()

  dispatch(appletIsSaving(true))

  api
    .post(config.createPath, { id: applet.id })
    .then(data => {
      // Enabling applet first time without config.
      dispatch(appletIsSaving(false))
      dispatch(appletCreated(true))
      dispatch(appletEnabled(true))
      dispatch(appletDisabled(false))
      dispatch(setApplet(data.applet))
      window.App.Utils?.logCustomDatadogAction?.("applet_enabled", { applet_id: applet.id })
    })
    .catch(() => {
      window.appendFlash(`There was an error creating the ${appletOrConnection(applet)}`, "danger")
      dispatch(appletIsSaving(false))
      dispatch(appletCreated(false))
      dispatch(appletEnabled(false))
      dispatch(connectClicked(false))
    })
}

export const updateApplet = () => (dispatch, getState, api) => {
  const {
    applet: { applet },
    config,
  } = getState()

  if (config.needsConfig && applet.status === "never_enabled_for_user") {
    window.location.assign(config.editPath)
  } else {
    dispatch(connectClicked(true))

    let patch_params = {}
    if (applet.status === "disabled_for_user") {
      patch_params["preserve_applet_fields"] = true
    }

    api
      .patch(config.savePath, patch_params)
      .then(data => {
        dispatch(appletDisabled(false))
        dispatch(appletCreated(true))
        dispatch(appletEnabled(true))
        dispatch(appletUpdated(true))
        dispatch(setApplet(data.applet))
      })
      // we should probably display an error in the interface in this case
      .catch(async error => {
        dispatch(appletUpdated(false))

        const response = await error.response

        if (response.redirect_to) {
          window.location.assign(response.redirect_to)
        } else {
          window.appendFlash(`There was an error updating this ${appletOrConnection(applet)}`, "danger")
        }
      })
  }
}

export const disableApplet = () => (dispatch, getState, api) => {
  const { config } = getState()

  api
    .post(config.disablePath)
    .then(data => {
      dispatch(appletDisabled(true))
      dispatch(appletCreated(false))
      dispatch(appletEnabled(false))
      dispatch(disconnectClicked(true))
      dispatch(resetConnectClicked)
      dispatch(setApplet(data.applet))
    })
    // we should probably display an error in the interface in this case
    .catch(({ response }) => {
      const {
        applet: { applet },
      } = getState()

      dispatch(appletDisabled(false))

      response.then(result => {
        if (result.action == "reload") {
          window.appendFlash(
            `There was an error disabling this ${appletOrConnection(applet)}. Reloading the page.`,
            "danger",
            () => {
              window.location.reload()
            }
          )
        } else {
          window.appendFlash(`There was an error disabling this ${appletOrConnection(applet)}.`, "danger")
        }
      })
    })
}

export default function applet(state = initialState, action) {
  switch (action.type) {
    case "SET_APPLET": {
      return {
        ...state,
        applet: {
          ...state.applet,
          appletDetails: state.applet?.appletDetails,
          ...action.payload,
        },
      }
    }

    case "APPLET_ENABLED": {
      return {
        ...state,
        enabled: action.payload,
      }
    }

    case "APPLET_UPDATED": {
      return {
        ...state,
        updated: action.payload,
      }
    }

    case "CREATE_APPLET": {
      return {
        ...state,
        createRequested: action.payload,
      }
    }

    case "APPLET_CREATED_SUCCESS": {
      return {
        ...state,
        created: action.payload,
      }
    }

    case "APPLET_DISABLED_SUCCESS": {
      return {
        ...state,
        disabled: action.payload,
      }
    }

    case "APPLET_SAVING": {
      return {
        ...state,
        appletIsSaving: action.payload,
      }
    }

    case "UPDATE_APPLET_ENABLEMENT_RULES": {
      return {
        ...state,
        applet: {
          ...state.applet,
          appletDetails: {
            ...state.applet.appletDetails,
            normalized_applet: {
              ...state.applet.appletDetails.normalized_applet,
              enablement_rules: action.payload,
            },
          },
        },
      }
    }

    default: {
      return state
    }
  }
}
