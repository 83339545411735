import { Component } from "react"
import PropTypes from "prop-types"

const baseName = "switch"

export default class Switch extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    if (this.props.onChange) {
      this.props.onChange(!this.props.value)
    }
  }

  getCircleColor() {
    if (this.props.value) {
      return this.props.circleColorEnabled
    } else {
      return this.props.circleColor
    }
  }

  getBackgroundColor() {
    if (this.props.value) {
      return this.props.backgroundColorEnabled
    } else {
      return this.props.backgroundColor
    }
  }

  render() {
    const uiStyles = {
      backgroundColor: this.getBackgroundColor(),
    }

    const labelOffStyles = {
      color: this.props.textColor,
      opacity: this.props.value ? 0 : 1,
    }

    const labelOnStyles = {
      color: this.props.textColor,
      opacity: this.props.value ? 1 : 0,
    }

    const circleContainerStyles = {
      transform: this.props.value ? "translateX(100%)" : "translateX(0%)",
    }

    const circleStyles = {
      backgroundColor: this.getCircleColor(),
    }

    const switchClassToggle = !this.props.value ? "disabled" : ""

    return (
      <div className={baseName}>
        <div className={`${baseName}-ui ${switchClassToggle}`} style={uiStyles}>
          <div className={`${baseName}-label ${baseName}-container-off`}>
            <div className={`${baseName}-label-off`} style={labelOffStyles}>
              {this.props.labels.off}
            </div>
          </div>
          <div className={`${baseName}-label ${baseName}-container-on`}>
            <div className={`${baseName}-label-on`} style={labelOnStyles}>
              {this.props.labels.on}
            </div>
          </div>
          <div
            className={`${baseName}-circle-container`}
            style={circleContainerStyles}
          >
            <span className={`${baseName}-circle`} style={circleStyles} />
          </div>
        </div>
        <button
          className={`${baseName}-button`}
          disabled={this.props.disabled}
          onClick={this.handleClick}
          type="button"
        />
      </div>
    )
  }
}

Switch.propTypes = {
  disabled: PropTypes.bool,
  labels: PropTypes.shape({
    on: PropTypes.string,
    off: PropTypes.string,
  }),
  onChange: PropTypes.func,
  value: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundColorEnabled: PropTypes.string,
  circleColor: PropTypes.string,
  circleColorEnabled: PropTypes.string,
  textColor: PropTypes.string,
}

Switch.defaultProps = {
  disabled: false,
  labels: { on: "", off: "" },
  onChange: () => {},
  value: false,
  circleColorEnabled: "#70d600",
  backgroundColorEnabled: "#fff",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
}
