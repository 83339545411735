import VanillaFittty from "shared/scripts/vanilla_fittty"

const ServiceTabs = () => {
  const serviceTabsData = JSON.parse(document.querySelector("[data-service-tabs]")?.dataset?.serviceTabs || "{}")
  const { signedIn, myAppletsUrl, discoverAppletsUrl, currentTab } = serviceTabsData

  // Signed-out users load Applets in backend and it's cached. It doesn't have my Applets.
  if (signedIn) {
    const loadApplets = (url:string, containerClass:string) => {
      const appletContainer = document.querySelector(`section[data-tab] ${containerClass}`)

      if (!appletContainer) return

      fetch(url)
        .then(response => response.text())
        .then(html => {
          appletContainer.classList.remove("loading")
          appletContainer.innerHTML = html
          VanillaFittty(appletContainer)
        })
    }

    if (currentTab === "my_applets") {
      loadApplets(myAppletsUrl, ".my_applets")
      loadApplets(discoverAppletsUrl, ".discover_services")
    } else {
      loadApplets(discoverAppletsUrl, ".discover_services")
      loadApplets(myAppletsUrl, ".my_applets")
    }
  }

  document.querySelectorAll(".tab-content.filters a").forEach(tab => {
    tab.addEventListener("click", (event) => {
      event.preventDefault()
      const tab = event.target.closest("a")

      if (tab.classList.contains("active")) return

      document.querySelectorAll(".tab-content.filters a").forEach(tab => tab.classList.remove("active"))
      tab.classList.add("active")

      const tabContent = document.querySelector(`section[data-tab=${tab.dataset.target}]`)
      document.querySelectorAll("section[data-tab]").forEach(content => content.classList.add("hide"))
      tabContent?.classList.remove("hide")

      VanillaFittty(tabContent)

      window.history.replaceState({ url: tab.href }, "", tab.href)
    })
  })
}

export default ServiceTabs
