import { ChangeEvent, ReactElement, useState } from "react"
import classNames from "classnames"
import submitFeedback from "./submit"
import { useLocalStorage } from "shared/use_local_storage_hook"
import StarIcon from "shared/components/icons/star_icon"
import XIcon from "shared/components/icons/x_icon"
import parse from "html-react-parser"

import "./feedback.scss"

interface Props {
  saveFeedbackUrl: string
  cause: string
  feedbackHeartHands: string
}

const badTags = [
  "Hard to use",
  "Not Useful",
  "Confusing",
  "It doesn’t work",
  "My service isn’t listed",
  "Difficult to configure",
]

const goodTags = [
  "Easy to use",
  "Fun",
  "Useful",
  "Saves time / money",
  "Looks Good",
]

const storageId = "firstTimeUserFeedback"

const FeedbackPrompt = ({
  saveFeedbackUrl,
  cause,
  feedbackHeartHands
}: Props): ReactElement | null => {
  const [show, setShow] = useLocalStorage(storageId, true)
  const [step, setStep] = useState<number>(1)
  const [rating, setRating] = useState<number>(0)
  const [hoveredRating, setHoveredRating] = useState<number>(0)
  const [chosenTags, setChosenTags] = useState<string[]>([])
  const [comments, setComments] = useState<string>("")
  const [submitted, setSubmitted] = useState<boolean>(false)

  if (show === false) return null

  const handleClickRating = (rating: number) => {
    if (step > 1) return

    setRating(rating)
    setStep(2)
  }
  const handleClickTag = (tag: string) => {
    if (chosenTags.includes(tag)) {
      setChosenTags(chosenTags.filter(t => t !== tag))
    } else {
      setChosenTags([...chosenTags, tag])
    }
  }

  const handleComments = (event: ChangeEvent<HTMLInputElement>) =>
    setComments(event.target.value)

  const handleSubmit = async () => {
    await submitFeedback(saveFeedbackUrl, rating, cause, chosenTags, comments)
    setSubmitted(true)
    setStep(4)
    setTimeout(() => setShow(false), 5000)
  }

  const handleClose = () => {
    if (submitted === false && rating > 0) handleSubmit()
    setShow(false)
  }

  let header = "",
    textPrompt = "",
    placeholderText = "",
    tags: string[] = []

  switch (step) {
    case 1:
      header = "Enjoying IFTTT?"
      textPrompt = "How would you rate your experience so far?"
      break
    case 2:
      header = rating > 3 ? "What did you like?" : "What went wrong?"
      tags = rating > 3 ? goodTags : badTags
      break
    case 3:
      header = ""
      placeholderText = `[Optional] ${
        rating > 3
          ? "Feel free to suggest any areas with room for improvement"
          : "Tell us what went wrong and we'll work on it."
      }`
      break
    case 4:
      header = "Thanks for your feedback!"
      break
  }

  return (
    <div styleName="feedback-bump">
      <div styleName="close" onClick={handleClose}>
        <XIcon width="18" height="18" />
      </div>
      {header && [1, 4].includes(step) && <h4>{header}</h4>}
      {step < 4 ? (
        <>
          {textPrompt && <p>{textPrompt}</p>}
          <div styleName="stars" onMouseLeave={() => setHoveredRating(0)}>
            {[...Array(5)].map((_, i) => (
              <StarIcon
                fill={i < rating}
                rating={i + 1}
                handleClickRating={handleClickRating}
                key={i}
                rated={rating > 0}
                setHoveredRating={setHoveredRating}
                hovered={i < hoveredRating}
              />
            ))}
          </div>
          {header && step > 1 && <h6 styleName="middle">{header}</h6>}
          <div styleName="tags">
            {tags.map(tag => (
              <span
                styleName={classNames("tag", {
                  tagged: chosenTags.includes(tag),
                })}
                onClick={() => handleClickTag(tag)}
                key={tag}
              >
                {tag}
              </span>
            ))}
          </div>
          {tags.length > 0 && (
            <div styleName="advance-step">
              <button
                className="button-tertiary button-on-color"
                onClick={() => setStep(3)}
              >Continue</button>
            </div>
          )}
          {step == 3 && (
            <>
              <textarea
                placeholder={placeholderText}
                styleName="text-box"
                value={comments}
                onChange={handleComments}
              />
              <div styleName="advance-step">
                <button
                  className="button-tertiary button-on-color"
                  onClick={handleSubmit}
                >Submit</button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
        <span>{parse(feedbackHeartHands)}</span>
        <p styleName="help">Need help? <a href="https://help.ifttt.com/" target="_blank" rel="noopener noreferrer"><b>Check your Help Center</b></a></p>
        </>
      )}
    </div>
  )
}

export default FeedbackPrompt
