import { clearState } from "shared/localstorage_helpers"

export default function config(state = {}, action) {
  switch (action.type) {
    case "SET_CONFIG": {
      return {
        ...state,
        ...action.payload,
      }
    }

    case "CLEAR_LOCAL_STORAGE": {
      clearState(state.localStorageId)
      return {
        ...state,
        localStorageCleared: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
